<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">KPI 샘플</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="con-top mb-20">
                <div class="con-top-txt">
                    - 주요 업적 및 성과 위주로 작성해 주세요.<br>
                    - 상위평가자가 작성하는 부분은 작성하실 수 없습니다. (평가자 의견, 역량평가 부문, 경영지원 평가 항목)<br>
                    - 업적 및 성과를 추가로 작성하시려면 아래 [ + 항목추가 ] 버튼을 클릭하세요.
                </div>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-550px">
                <div class="block-title">업적평가 부문</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="*">
                        <col width="250">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>2010년 주요 업적 및 성과</th>
                            <th>계량적 기여도</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="pt-15 pl-15 pr-15 pb-15">
                                [B2B업무 성과]<br>
                                2013년 개인목표매출 7억 1천(11년) -> 8억 4천(12년)<br>
                                1. 신규고객사 마케팅 및 오픈 : A공사(15명~20명), B기업(50명~80명)<br>
                                / C기업(30명), D기업(30명), E기업(2명), F기업(3명)<br>
                                2. 기존업체 재수강 및 매출 확대 : A,B, C 기업(기여), D기업(기여), E기업 2,557명 -> 3,924명<br>
                                3. BtoB 신규업체오픈 및 f/up : AAA(인수), BBB(인수), CCC, DDD...<br>
                                4. BtoB 고객관리업무<br>
                                평균만족도 6.00이상, 학습자관리 담당자미팅, 과정제안미팅<br>
                                필리핀센터 이원화에 따른 Q.C관리, 강사 quit에 따른 학습자 TR
                            </td>
                            <td>
                                2013년 B2B전체 매출중 21%기여<br>
                                총 8억 4천<br>
                                * AA기업 2억 -> 3억 8천( 190%성장 )<br>
                                2013년 연간평균관리<br>
                                고객수 780명<br>
                                *4월~12월 : 평균 906명 관리
                            </td>
                        </tr>
                        <tr>
                            <td class="pt-15 pl-15 pr-15 pb-15">
                                [조직관리부문]<br>
                                1. 퇴사자없는 안정적인 조직운영 관리 &amp; 조직원의 성장<br>
                                2. 파트원 성장- AA님(6억 3천)<br>
                                3. BtoB 신규직원교육 - A, B, C, D<br>
                                4. 글로벌/전화사업부 신입사원 전화사업교육 - 하반기 10월 입사자 대상
                            </td>
                            <td>
                                AA파트 매출 : BtoB 전체 매출 중 37% 기여(14억 7천)<br>
                                <br>
                                연간 편균관리고객수 672명
                            </td>
                        </tr>
                        <tr>
                            <td class="pt-15 pl-15 pr-15 pb-15">
                                [시스템개선 및 분석보고]<br>
                                1. BtoB교육과정 현황분석보고<br>
                                2. 필리핀 센터 매니저 페이지, pay System화<br>
                                3. 연간 파일럿진행 및 분석보고
                            </td>
                            <td>
                                2013 파일럿 총 19건 중 17건진행
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'KPISamplePopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>
<template>
    <div class="con-wrap">
        <KPISamplePopup v-if="pop.isShow" @close="pop.isShow=false"></KPISamplePopup>
        <CarrotTitle title="KPI 작성">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default" @click="pop.isShow=true">샘플보기</button>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                             <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="210">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ add.info.sector_name }}</td>
                                <td>{{ add.info.office_name }}</td>
                                <td>{{ add.info.team_name }}</td>
                                <td>{{ add.info.ename }}({{ add.info.kname }})</td>
                                <td>{{ add.info.position_name }}</td>
                                <td>{{ add.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left mt-10">업적평가 부문</span>
                        <button class="btn-default float-right" @click="add.addItem">+ 항목추가</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="800">
                            <col width="400">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{{ add.year }}년 주요 업적 및 성과</th>
                                <th>계량적 기여도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in add.list" :key="i">
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px"  v-model="irow.achievement"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="irow.contribution"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table-row mt-50">
                        <colgroup>
                            <col width="170">
                            <col width="130">
                            <col span="3">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>업적종합평가</th>
                                <th>본인평가</th>
                                <td>
                                    <label><input type="radio" id="Self_Ass1" name="Self_Ass" value="3" v-model="add.score"><span class="ml-5 mr-20"> Exceptional</span></label>
                                </td>
                                <td>
                                    <label><input type="radio" id="Self_Ass2" name="Self_Ass" value="2" v-model="add.score"><span class="ml-5 mr-20"> Excellent</span></label>
                                </td>
                                <td>
                                    <label><input type="radio" id="Self_Ass3" name="Self_Ass" value="1" v-model="add.score"><span class="ml-5 mr-20"> Need to Improve</span></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
                    <button class="btn-default float-right mt-30 ml-20" @click="add.doSubmit">제출</button>
                    <button class="btn-default float-right mt-30" @click="add.tempSave">임시저장</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import KPISamplePopup from '@/components/popup/myPIMS/KPISamplePopup.vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';


export default {
    layout:"myPIMS",
    components: {
        KPISamplePopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast()

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const add = reactive({
            // New Post
            idx  : 0,
            year : "",
            info : { 
                idx:0, office_name:"", team_name:"", ename:"", kname:"", position_name:"", joindate:""
            },
            list : [
                { achievement:"", contribution:"" },
                { achievement:"", contribution:"" },
                { achievement:"", contribution:"" },
            ],
            score : "",
            state : "T",

            addItem : () => {
                add.list.push(
                    { achievement:"", contribution:"" },
                );
            },

            doSearch : () => {
                axios.get("/rest/mypims/getMyKPI", { params : {} }).then((res) => {
                    if( res.data.err == 0 ){
                        add.info = res.data;

                        if( res.data.has_temp == "Y" ) {
                            add.idx   = res.data.temp.idx;
                            add.year  = res.data.temp.wyear;
                            add.list  = res.data.temp.list ? res.data.temp.list : add.list;
                            add.score = res.data.temp.score;
                        } else {
                            if( typeof res.data.ev == 'object' ){
                                router.push({
                                    name : 'myPIMS-KPIView-idx',
                                    params : {
                                        idx : res.data.ev.idx
                                    }
                                });
                            }
                        }
                    } else {
                        console.info(res);
                    }
                });
            },

            tempSave : () => {
                let params = {
                    idx        : add.idx,
                    wyear      : add.year,
                    evaluation : add.list,
                    score      : add.score,
                    state      : "T"
                };

                axios.post("/rest/mypims/addKPI", params).then((res) => {
                    if( res.data.err == 0 ){
                        add.idx = res.data.idx;
                        toast.success("임시저장 하였습니다.");
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx        : add.idx,
                    wyear      : add.year,
                    evaluation : add.list,
                    score      : add.score,
                    state      : "Y"
                };

                if( params.score == "" || params.score == "0" ){
                    Swal.fire({
                        title : "KPI 작성",
                        text  : "본인평가를 선택하세요."
                    });
                    return;
                }

                Swal.fire({
                    title : 'KPI 작성',
                    text  : '제출 후에는 수정이 불가합니다. 제출하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        axios.post("/rest/mypims/addKPI", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("제출하였습니다.");
                                router.push({
                                    name : "myPIMS-PBCKPIList"
                                });
                            } else {
                                if( res.data.err_msg ) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            goList : () => {
                Swal.fire({
                    title : 'KPI 작성',
                    text  : '입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        router.push({
                            name : "myPIMS-PBCKPIList",
                            params : route.params
                        });
                    }
                });
            }
        });
        
        onMounted(() => {
            let td = new Date();
            add.year = td.getFullYear()-1;
            add.doSearch();
        });

        onUnmounted(() => {

        });

        return {pop, add};
    }
}
</script>

<style lang="scss" scoped>
</style>